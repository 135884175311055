import {
  addDays,
  format,
  fromUnixTime,
  getUnixTime,
  parse,
  startOfDay,
} from "date-fns";

import React from "react";

export default function LastAccessedReport() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const submit = React.useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    fetch(`/.netlify/functions/get-last-accessed-range`, {
      method: "POST",
      body: JSON.stringify({
        startDate: getUnixTime(startDate),
        endDate: getUnixTime(endDate),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "succeeded") {
          // set result data
          setData(result.data);
        }
      })
      .catch(() => {
        console.log("error submitting report date range");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading, setLoading, startDate, endDate, setData]);

  return (
    <div>
      <h2 className="title is-size-4 has-text-weight-bold is-bold-light">
        Last accessed
      </h2>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          type="date"
          onChange={(e) => {
            const now = startOfDay(new Date());
            const parsedDate = parse(e.target.value, "yyyy-MM-dd", now);
            setStartDate(parsedDate);
          }}
        />
        <input
          type="date"
          onChange={(e) => {
            const now = startOfDay(new Date());
            const parsedDate = addDays(
              parse(e.target.value, "yyyy-MM-dd", now),
              1
            );
            setEndDate(parsedDate);
          }}
        />
      </div>

      <button className="fancy" onClick={submit} disabled={loading}>
        Submit
      </button>

      {!!loading && (
        <div>
          <span>Loading...</span>
        </div>
      )}

      {data.length > 0 && (
        <div style={{ marginTop: 40 }}>
          <strong>{data.length} result(s) found</strong>
          <br />
          {data.map((x) => (
            <span>
              {x.username},{x.email},{x.phone || ""},{x.subscription_status},
              {format(
                fromUnixTime(x.last_accessed_timestamp),
                "MM/dd/yyyy hh:mm a"
              )}
              <br />
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
