import * as ROLES from "../../constants/roles";

import { format, startOfWeek } from "date-fns";
import {
  withAuthorization,
  withEmailVerification,
} from "../../components/Session";

import LastAccessedReport from "./LastAccessedReport";
import Layout from "../../components/Layout";
import React from "react";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";

const dayKeys = ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"];
const initialRatings = {
  "Day 1": [],
  "Day 2": [],
  "Day 3": [],
  "Day 4": [],
  "Day 5": [],
};

function ReportBase({ firebase }) {
  const [stats, setStats] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [ratings, setRatings] = React.useState(initialRatings);
  const [unsubscribe, setUnsubscribe] = React.useState();
  const now = new Date();
  const weekStart = startOfWeek(now);

  React.useEffect(() => {
    if (firebase && !loaded) {
      const unsub = firebase
        .ratings()
        .where(
          "description",
          "==",
          `week-of-${format(weekStart, "yyyy-MM-dd")}`
        )
        .onSnapshot((snapshot) => {
          const dbRatings = { ...initialRatings };

          snapshot.forEach((doc) => {
            const data = doc.data();
            dbRatings[data.value].push({ ...data, id: doc.id });
          });

          setRatings(dbRatings);
          setLoaded(true);
          setUnsubscribe(unsub);
        });
    }
    return () => unsubscribe;
  }, [firebase, loaded, weekStart, unsubscribe]);

  React.useEffect(() => {
    const newStats = dayKeys.map((dayKey) => {
      const ratingsForDay = ratings[dayKey];
      if (ratingsForDay.length > 0) {
        const ratingValues = ratingsForDay.map((x) => x.rating);
        const min = Math.min(...ratingValues);
        const max = Math.max(...ratingValues);
        const average =
          ratingValues.reduce((a, b) => a + b, 0) / ratingValues.length;

        return (
          <div key={`day-ratings-${dayKey}`}>
            <strong>{ratingsForDay[0].note || ""}</strong>,{" "}
            {ratingsForDay.length} ratings - min: {min}, max: {max},{" "}
            <strong>average:</strong> {average.toFixed(1)}
          </div>
        );
      }

      return <div key={`day-ratings-${dayKey}`} />;
    });
    setStats(newStats);
  }, [ratings]);

  if (!loaded) {
    return null;
  }

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-12">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              Reports
            </h1>

            <div>
              <h2 className="title is-size-4 has-text-weight-bold is-bold-light">
                Ratings for week of {format(weekStart, "MMMM dd, yyyy")}
              </h2>
              {stats.map((x) => x)}
            </div>
            <LastAccessedReport />
          </div>
        </div>
      </div>
    </section>
  );
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const ReportPage = compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase
)(ReportBase);

const ReportPageFinal = () => (
  <Layout>
    <ReportPage />
  </Layout>
);

export default ReportPageFinal;
